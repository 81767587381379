import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

export const DropPlayerDialog = ({open, handleClose, dropRow, handleDropPlayer}) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="place-bid-dialog">
                Drop player?
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`${dropRow.first_name} ${dropRow.last_name} - ${dropRow.pos} - ${dropRow.team}`}
                </DialogContentText>
                <DialogContentText>
                    Penalty will be {`${dropRow.penalty ? dropRow.penalty : 0}`}
                </DialogContentText>
                <Button variant="outlined" type="submit" color="primary" style={{margin: 20}}
                        onClick={handleClose}>Cancel</Button>
                <Button variant="contained" type="submit" color="primary" style={{margin: 20}}
                        onClick={handleDropPlayer}>Drop</Button>
            </DialogContent>
        </Dialog>
    );
}