import React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";

const managerCorrectCounts = {};
const renderPickCell = (params) => {
    const [winLose, team, resultsStr] = `${params.row[params.field]}`.split('|')
    const results = resultsStr.split('.');
    const gameTableCells = [];
    for (let c = 0; c < results.length; c++) {
        const result = results[c];
        const color = (result === 'O' || result === 'X') ? '' : result === winLose ? 'lightgreen' : 'lightcoral';
        gameTableCells.push(
            <TableCell key={`${team}${winLose}${c}`} style={{padding: 0, textAlign: "center", backgroundColor: color}}>{result}</TableCell>
        );
    }
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={5} style={{textAlign: "center"}}>
                            <span>{team}</span>
                            <span> - </span>
                            <span style={winLose === 'W' ? {color: "green"} : {color: "red"}}>{winLose}</span>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {gameTableCells.slice(0, 5)}
                    </TableRow>
                    <TableRow>
                        {gameTableCells.slice(5, 10)}
                    </TableRow>
                    <TableRow>
                        {gameTableCells.slice(10, 15)}
                    </TableRow>
                    <TableRow>
                        {gameTableCells.slice(15, results.length + 1)}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const colWidth = 150;
const columns = [
    {
        sortable: false, disableColumnMenu: true, width: 60, headerName: ' ', field: 'id'
    }
];

const buildColumns = (picks) => {
    let cols = [];
    const managers = [];
    for (const pick of picks) {
        if (!managers.includes(pick.manager_abbr)) {
            managers.push(pick.manager_abbr);
            managerCorrectCounts[pick.manager_abbr] = 0;
            cols.push({
                sortable: false, disableColumnMenu: true, width: colWidth,
                headerName: pick.manager_name, field: pick.manager_abbr, headerAlign: 'center', align: 'center',
                renderCell: renderPickCell
            })
        }
    }
    cols.sort((a, b) => (a.headerName > b.headerName) ? 1 : ((b.headerName > a.headerName) ? -1 : 0));
    cols = [...columns, ...cols];
    return cols;
};

const buildRowData = (picks, gameResults, cols) => {
    const rows = [];
    const cellsInCols = {};
    if (picks.length > 0 && Object.keys(gameResults).length > 0) {
        for (const pick of picks) {
            if (!cellsInCols[pick.manager_abbr]) cellsInCols[pick.manager_abbr] = [];
            const gameResultsString = buildResultsString(gameResults[pick.team_abbr]);
            const managerCorrectCount = (gameResultsString.match(pick.wins ? /W/g : /L/g) || []).length;
            managerCorrectCounts[pick.manager_abbr] += managerCorrectCount;
            const cell = `${pick.wins ? 'W' : 'L'}|${pick.team_abbr}|${gameResultsString}`;
            cellsInCols[pick.manager_abbr].push(cell);
        }
        const rowCount = picks.length / (cols.length - 1);
        for (let rowNum = 1; rowNum <= rowCount; rowNum++) {
            const row = {id: rowNum};
            rows.push(row);
        }
        for (const col of cols) {
            const managerColumnCells = cellsInCols[col.field];
            if (managerColumnCells) {
                managerColumnCells.forEach((cell, i) => {
                    rows[i][col.field] = cell;
                });
            }
        }
    }
    return rows;
};

const buildResultsString = (resultsMap) => {
    const resultsChars = [];
    const weekNumsPlayed = Object.keys(resultsMap).map(n => parseInt(n));
    const lastWeekNumPlayed = Math.max(...weekNumsPlayed);
    for (let w = 1; w <= lastWeekNumPlayed; w++) {
        if (!weekNumsPlayed.includes(w)) {
            resultsChars.push('X');
        } else {
            resultsChars.push(resultsMap[w] === null ? 'O' : resultsMap[w]);
        }
    }
    return resultsChars.join('.')
};

const buildManagerRankings = (counts) => {
    const sorted = [...counts].sort((a, b) => b - a);
    return counts.map((x) => sorted.indexOf(x) + 1);
}

export const WinsPoolResultsGrid = ({picks, gameResults, loading}) => {
    const cols = buildColumns(picks);
    const rows = buildRowData(picks, gameResults, cols);
    return (
        <div style={{width: 71 + (8 * colWidth)}}>
            {loading && <LinearProgress color="secondary"/>}
            {cols.length > 0 && rows.length > 0 && Object.keys(gameResults).length > 0 &&
                <DataGrid columns={cols} rows={rows} columnHeaderHeight={30} rowHeight={150} autoHeight
                          disableRowSelectionOnClick disableMultipleRowSelection disableColumnResize
                          slots={{footer: WinsPoolResultsGridFooter}} slotProps={{footer: {cols: cols}}}/>
            }
        </div>
    );
};

export const WinsPoolResultsGridFooter = ({cols}) => {
    const cellStyle = {width: colWidth, textAlign: "center"};
    const correctCountCells = [];
    const countValues = [];
    for (let c = 1; c < cols.length; c++) {
        correctCountCells.push(
            <td key={`T${c}`} style={cellStyle}>{managerCorrectCounts[cols[c].field]}</td>
        );
        countValues.push(managerCorrectCounts[cols[c].field]);
    }
    const rankings = buildManagerRankings(countValues);
    const rankingCells = [];
    for (let c = 0; c < cols.length - 1; c++) {
        rankingCells.push(
            <td key={`R${c}`} style={cellStyle}>{rankings[c]}</td>
        );
    }
    return (
        <div style={{marginTop: 20}}>
            <table>
                <tbody>
                <tr>
                    <td key="Total" style={{width: 40, paddingLeft: 10, textAlign: "left"}}>Total</td>
                    {correctCountCells}
                </tr>
                <tr>
                    <td key="Rank" style={{width: 40, paddingLeft: 10, textAlign: "left"}}>Rank</td>
                    {rankingCells}
                </tr>
                </tbody>
            </table>
        </div>
    );
};
