import React, {useEffect, useState} from "react";
import {apiGet} from "../utils";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {WinsPoolResultsGrid} from "../components/WinsPoolResultsGrid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {WinsPoolChart} from "../components/WinsPoolChart";
import {useMediaQuery} from "@mui/material";

export const WinsPoolContainer = () => {
    const [seasonYears, setSeasonYears] = useState([]);
    const [picks, setPicks] = useState([]);
    const [gameResults, setGameResults] = useState({});
    const [anchorElSeason, setAnchorElSeason] = useState(null);
    const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(null);
    const [showChart, setShowChart] = useState(false);
    const [loading, setLoading] = useState(true);

    const isMobile = useMediaQuery('(max-width: 1275px)');

    const fetchWinsSeasonYears = () => {
        setLoading(true);
        apiGet(`wins/seasons`, (data) => {
            setSeasonYears(data.data ? data.data.map(s => s.year) : []);
            setSelectedSeasonIndex(data.data.length - 1);
            setLoading(false);
        });
    };

    const fetchWinsPoolPicks = (season = null) => {
        setLoading(true);
        const urlSeasonParam = (season != null) ? `/season/${season}` : '';
        apiGet(`wins/picks${urlSeasonParam}`, (data) => {
            setPicks(data.data ? data.data : []);
            setLoading(false);
        });
    };

    const fetchGameResults = (season = null) => {
        setLoading(true);
        const urlSeasonParam = (season != null) ? `/season/${season}` : '';
        apiGet(`wins/results${urlSeasonParam}`, (data) => {
            setGameResults(data.data ? data.data : {});
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchWinsSeasonYears();
        fetchWinsPoolPicks();
        fetchGameResults();
    }, []);

    const handleClickSeason = (event) => {
        setAnchorElSeason(event.currentTarget);
    };

    const handleSeasonMenuItemClick = (event, index) => {
        setSelectedSeasonIndex(index);
        setAnchorElSeason(null);
        fetchWinsPoolPicks(seasonYears[index]);
        fetchGameResults(seasonYears[index]);
    };

    const handleClose = () => {
        setAnchorElSeason(null);
    };

    const handleClickShowChart = () => {
        setShowChart(true);
    };

    const justify = isMobile ? 'flex-start' : 'center';
    return (
        <Grid container justifyContent={justify}>
            <table>
                <tbody>
                <tr>
                    <td>
                        <div style={{textAlign: "center", marginTop: 50, marginBottom: 50, marginLeft: 10, marginRight: 10}}>
                            <div style={{textAlign: "right", marginBottom: 10}}>
                                Season:
                                <Button
                                    style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}}
                                    onClick={handleClickSeason}>{seasonYears[selectedSeasonIndex]}</Button>
                                <Menu id="seasonPickerMenu" anchorEl={anchorElSeason} keepMounted
                                      open={Boolean(anchorElSeason)}
                                      onClose={handleClose}>
                                    {seasonYears.map((seasonYear, index) => (
                                        <MenuItem key={seasonYear} selected={index === selectedSeasonIndex}
                                                  onClick={(event) => handleSeasonMenuItemClick(event, index)}>{seasonYear}</MenuItem>
                                    ))}
                                </Menu>
                            </div>
                            {loading && <LinearProgress color="secondary"/>}
                            <WinsPoolResultsGrid picks={picks} gameResults={gameResults}/>
                            <br/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{textAlign: "center"}}>
                            {!showChart &&
                                <Button
                                    style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}}
                                    onClick={handleClickShowChart}>Show Chart</Button>
                            }
                            {showChart &&
                                <div style={{marginBottom: 50, marginLeft: 10, marginRight: 10}}>
                                    <WinsPoolChart picks={picks} gameResults={gameResults}/>
                                </div>
                            }
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </Grid>
    );
}