import React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {Button} from "@material-ui/core";
import {formatTimestampWithDOW} from "../utils";

let onBiddingClick;
const renderBiddingButton = (params) => {
    //TODO: maybe always show this button and move conditional into the modal... so you can always click to see bids in that group...
    if (params.row.bid_group_is_open) {
        const onClick = async () => {
            onBiddingClick(params.row)
        }
        return (
            <Button color="primary" onClick={onClick}>Bidding</Button>
        );
    } else {
        return (<div/>);
    }
};

const bidGroupListColumns = [
    {
        sortable: false, disableColumnMenu: true, width: 130,
        headerName: 'First Name', field: 'first_name'
    },
    {
        sortable: false, disableColumnMenu: true, width: 130,
        headerName: 'Last Name', field: 'last_name'
    },
    {
        sortable: false, disableColumnMenu: true, width: 60,
        headerName: 'Pos', field: 'pos', headerAlign: 'center', align: 'center'
    },
    {
        sortable: false, disableColumnMenu: true, width: 70,
        headerName: 'Team', field: 'team', headerAlign: 'center', align: 'center'
    },
    {
        sortable: false, disableColumnMenu: true, width: 200,
        headerName: 'Game Time', field: 'game_time', headerAlign: 'left', align: 'left',
        valueGetter: (value) => {
            return value ? formatTimestampWithDOW(value) : '';
        }
    },
    {
        sortable: false, disableColumnMenu: true, width: 90,
        headerName: 'High Bid', field: 'high_bid', type: 'number', headerAlign: 'right'
    },
    {
        sortable: false, disableColumnMenu: true, width: 110,
        headerName: 'High Bidder', field: 'high_bidder', headerAlign: 'center', align: 'center'
    },
    {
        sortable: false, disableColumnMenu: true, width: 105,
        headerName: ' ', field: 'bidding', align: 'center',
        renderCell: renderBiddingButton
    }
];

const determineRowClass = (params) => {
    return `${params.row.row_number % 2 === 1 ? 'table-row-odd' : 'table-row-even'}`
};

export const BidGroupGrid = ({rows, onBiddingButtonClick}) => {
    onBiddingClick = onBiddingButtonClick;
    return (
        <div style={{width: 900}}>
            <DataGrid columns={bidGroupListColumns} rows={rows} density="compact" hideFooter={true} autoHeight
                      getRowClassName={(params) => (determineRowClass(params))} disableSelectionOnClick/>
        </div>
    );
}
