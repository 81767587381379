import React, {useCallback, useState} from 'react';
import {Typography} from "@material-ui/core";
import {config} from '../config';
import {WeekPicker} from "../components/WeekPicker";
import {RosterGrid} from '../components/RosterGrid';
import {apiGet} from "../utils";
import Grid from "@material-ui/core/Grid";
import {useMediaQuery} from "@mui/material";

const managerNames = config.managerNames;
const tableData = {rosters: {0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: []}};
const totalSpent = {banks: {0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: []}};
const loadings = {0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true};

export const SpreadsheetContainer = () => {
    const [dummy, setDummy] = useState(-1);

    const isMobile = useMediaQuery('(max-width: 1420px)');

    const fetchRosterData = useCallback((season = null, weekNum = null) => {
        const urlDateParam = (season != null && weekNum != null) ? `/season/${season}/week/${weekNum}` : '';
        for (let i = 0; i < 8; i++) {
            apiGet(`contract/owned/${managerNames[i]}${urlDateParam}`, (ownedData) => {
                tableData.rosters[i] = ownedData.data;
                totalSpent.banks[i] = (ownedData.data.map(contract => contract.salary).reduce((a, b) => a + b));
                apiGet(`contract/penalty/${managerNames[i]}${urlDateParam}`, (penaltyData) => {
                    if (penaltyData.data) {
                        tableData.rosters[i] = [...tableData.rosters[i], ...penaltyData.data];
                        totalSpent.banks[i] += (penaltyData.data.map(contract => contract.penalty).reduce((a, b) => a + b));
                    }
                    loadings[i] = false;
                    setDummy(i);
                });
            });
        }
    }, []);

    const preHandler = () => {
        for (let i = 0; i < 8; i++) {
            loadings[i] = true;
        }
    };

    const tableRows = [];
    if (isMobile) {
        for (let i = 0; i < 8; i++) {
            tableRows.push(
                <tr key={`tr${i}t`}>
                    <td><Typography variant="h6" style={{margin: 10}}>{managerNames[i]}</Typography></td>
                </tr>);
            tableRows.push(
                <tr key={`tr${i}b`}>
                    <td style={{verticalAlign: 'top'}}>
                        <RosterGrid rows={tableData.rosters[i]} spent={totalSpent.banks[i]} loading={loadings[i]}
                                    showActions={false} dummy={dummy}/>
                    </td>
                </tr>)
        }
    } else {
        for (let i = 0; i < 8; i += 2) {
            tableRows.push(
                <tr key={`tr${i}`}>
                    <td><Typography variant="h6" style={{margin: 10}}>{managerNames[i]}</Typography></td>
                    <td><Typography variant="h6" style={{margin: 10}}>{managerNames[i + 1]}</Typography></td>
                </tr>);
            tableRows.push(
                <tr key={`tr${i + 1}`}>
                    <td style={{verticalAlign: 'top'}}>
                        <RosterGrid rows={tableData.rosters[i]} spent={totalSpent.banks[i]} loading={loadings[i]}
                                    showActions={false} dummy={dummy}/>
                    </td>
                    <td style={{verticalAlign: 'top'}}>
                        <RosterGrid rows={tableData.rosters[i + 1]} spent={totalSpent.banks[i + 1]} loading={loadings[i]}
                                    showActions={false} dummy={dummy}/>
                    </td>
                </tr>)
        }
    }

    const justify = isMobile ? 'flex-start' : 'center';
    return (
        <div>
            <Grid container justifyContent={justify}>
                <table>
                    <tbody>
                    <tr>
                        {!isMobile && <td/>}
                        <td align="right">
                            <WeekPicker fetchFunction={fetchRosterData} preHandler={preHandler}/>
                        </td>
                    </tr>
                    {tableRows}
                    <tr>
                        <td style={{height: 100}}/>
                        <td style={{height: 100}}/>
                    </tr>
                    </tbody>
                </table>
            </Grid>
        </div>
    );
}