import React from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarQuickFilter} from "@mui/x-data-grid";
import {formatTimestampWithDOW} from "../utils";
import {Box} from "@mui/material";

const activityLogGridColumns = [
    {
        sortable: false, disableColumnMenu: true, width: 200,
        headerName: 'Timestamp', field: 'ts', headerAlign: 'left', align: 'left',
        valueGetter: (value) => {
            return value ? formatTimestampWithDOW(value) : '';
        }
    },
    {
        sortable: false, disableColumnMenu: true, width: 110,
        headerName: 'Manager', field: 'manager', headerAlign: 'left', align: 'left'
    },
    {
        sortable: false, disableColumnMenu: true, width: 480,
        headerName: 'Description', field: 'summary', headerAlign: 'left', align: 'left'
    }
];

const determineRowClass = (params) => {
    return `${params.row.id % 2 === 1 ? 'table-row-odd' : 'table-row-even'}`
};

export const ActivityLogGrid = ({rows}) => {
    return (
        <div style={{width: 792, marginTop: 20}}>
            <DataGrid columns={activityLogGridColumns} rows={rows} density="compact" hideFooter={true} autoHeight
                      getRowClassName={(params) => (determineRowClass(params))} disableSelectionOnClick
                      slots={{toolbar: ActivityLogGridToolbar}}/>
        </div>
    );
}

function ActivityLogGridToolbar() {
    return (
        <GridToolbarContainer>
            <Box sx={{flexGrow: 1}}/>
            <GridToolbarQuickFilter/>
        </GridToolbarContainer>
    );
}
